header {
  position: sticky;
  top: 0;
  padding: 16px;
  height: 62px;
  width: 100%;
  background-color: #3f51b5;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 6px 0 rgba(34, 36, 38, 0.15),
    0 2px 18px 0 rgba(34, 36, 38, 0.17);
  z-index: 102;
}

header .ui.header {
  display: flex !important;
  flex-direction: row;
  align-items: stretch;
  vertical-align: middle;
}

header .content {
  flex: 1;
}

header .ui.header .icon {
  margin-top: 3px;
}
