.input-right {
  text-align: right;
}

.input-right input {
  text-align: right !important;
  font-family: 'Roboto Mono', monospace !important;
}

.transactions-form__empty {
  border: solid 1px #d4d4d5;
  border-radius: 0.28571429rem;
  padding: 1em;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .transactions-form__empty {
    border: none;
    border-top: solid 1px #d4d4d5;
    border-radius: 0;
  }
}

.transaction-form .ui.labeled.input .label {
  width: 4.9em !important;
  font-family: 'Roboto Mono', monospace !important;
}

.transaction-form-grid {
  display: flex;
  flex-direction: row;
}

.transaction-form-grid .transaction-form-grid__field {
  width: 100%;
}

.transaction-form-grid .transaction-form-grid__field:last-child {
  margin-top: 1em;
}

.transaction-form-grid__column-narrow .transaction-form-grid__field {
  width: 100%;
  margin-top: 1.65em;
}

.transaction-form-grid__column-narrow .transaction-form-grid__field:last-child {
  margin-top: 1em;
  padding-top: 0.1em;
}

.transaction-form-grid.single-line {
  margin-top: 0;
}

@media (min-width: 768px) {
  .input-right.no-label .input {
    margin-top: 1.65em;
  }

  .transaction-form-grid__column-wide {
    width: 68.75%;
    padding-right: 0.3em;
  }

  .transaction-form-grid__column-narrow {
    width: 31.25%;
    padding-left: 0.7em;
  }

  .transaction-form-grid.single-line .transaction-form-grid__field {
    margin-top: 0;
  }

  .transaction-form-grid.single-line
    .transaction-form-grid__column-narrow
    .transaction-form-grid__field:last-child {
    margin-top: 1em;
  }
}

@media (max-width: 767px) {
  .transaction-form-grid {
    margin-top: 1em;
    flex-direction: column;
  }

  .transaction-form-grid__column-wide {
    width: 100%;
  }

  .transaction-form-grid__column-narrow {
    width: 100%;
    display: flex;
  }

  .transaction-form-grid__column-narrow
    .transaction-form-grid__field:first-child {
    padding-right: 1.5em;
  }

  .transaction-form-grid__column-narrow .transaction-form-grid__field {
    margin-top: 1em;
    flex-grow: 1;
  }

  .ui[class*='top attached'].menu {
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
  }

  .ui.segment[class*='bottom attached'] {
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    padding-top: 0 !important;
  }

  .mobile-with-margin {
    margin-top: 1em !important;
  }
}
