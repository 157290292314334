@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,400,500');

body {
  height: auto !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@media (min-width: 768px) {
  body {
    background-color: #f5f5f5;
  }
}

nav {
  position: fixed;
  top: 60px;
  width: 125px;
  min-height: 100%;
  transition: transform 0.5s ease;
  z-index: 101;
}

@media (min-width: 1194px) {
  nav {
    box-shadow: 0 2px 6px 0 rgba(34, 36, 38, 0.15),
      0 2px 18px 0 rgba(34, 36, 38, 0.17);
  }
}

nav.open {
  transform: translate(0, 0);
}

nav.closed {
  transform: translate(-125px, 0);
}

nav .menu {
  min-height: 100vh !important;
  border: none !important;
  border-radius: 0 !important;
}

nav .menu .item:last-child {
  border-radius: 0 !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15) !important;
}

.ui.menu {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ui.blue.menu .active.item,
.ui.menu .blue.active.item {
  color: #3f51b5 !important;
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.ui.menu .active.item {
  box-shadow: inset 0 1px 4px 0 rgba(34, 36, 38, 0.12) !important;
}

.container-raised-desktop {
  display: block;
  background-color: white;
  padding: 1em 0 0 0;
  max-width: 700px;
  font-size: 1.14285714rem;
  line-height: 1.5;
}

.container-raised-desktop .ui.header,
.container-raised-desktop .ui.form,
.container-raised-desktop p {
  margin: 1rem;
}

.container-raised-desktop .ui.form {
  margin-bottom: 0;
}

.container-raised-desktop .form-submit {
  padding: 1em 0 0 0;
  text-align: right;
}

.container-raised-desktop .form-submit .button {
  margin: 0;
}

.container-full-page {
  display: block;
  background-color: white;
  font-size: 1.14285714rem;
  line-height: 1.5;
}

.ui.message {
  max-width: 960px !important;
  margin: 1em auto;
  padding: 1em !important;
}

.ui.message .content {
  margin-top: 0.5em;
}

@media (min-width: 768px) {
  .container {
    max-width: calc(100% - 125px);
    margin-left: 125px;
    margin-top: 1em;
  }

  .container-raised-desktop {
    margin: 1em auto;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
}

@media (min-width: 768px) and (max-width: 1193px) {
  html,
  body {
    background-color: white;
  }

  .container-full-page {
    padding: 0 1em 1em 1em;
    margin-left: 3px;
  }

  .ui.warning {
    margin: 1em 1em 0 !important;
  }
}

@media (min-width: 1194px) {
  .container-full-page {
    max-width: 960px;
    margin: 1em auto;
    padding: 0 1em 1em 1em;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
}

.container-full-page.flat {
  padding: 0;
}

header .ui.header {
  color: white;
}

header .ui.header h2 {
  font-weight: 300;
}

.ui.header:first-child {
  margin-top: 0;
}

.ui.header .icon {
  padding-top: 0;
  font-weight: 300;
}

.ui.primary.button {
  background-color: #3f51b5 !important;
}

.ui.button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ui.button,
.ui.label,
.ui.header {
  font-weight: 400 !important;
}

.ui.dropdown .menu .active.item {
  font-weight: 500 !important;
}

.ui.header {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin: 0;
}

.mono {
  font-family: 'Roboto Mono', monospace;
  color: #686869;
}

.positive {
  color: #21ba45;
}

.negative {
  color: #db2828;
}

@media (max-width: 767px) {
  .ui.grid {
    margin: 0;
  }

  .ui.grid .row .column {
    padding: 0;
  }

  .container-raised-desktop .form-submit {
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    padding: 1rem;
  }

  .container-full-page .ui.grid .row {
    padding: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  }

  .ui.warning {
    margin: 0 !important;
    box-shadow: none !important;
    padding: 1em !important;
  }

  .mt-settings .section__body {
    padding: 1em;
    border-top: solid 1px #d4d4d5;
  }
}

.ui.form .field > label {
  font-weight: 500;
}

.container-full-page .container-header {
  padding: 1em;
  border-bottom: solid 1px #d4d4d5;
  border-radius: 5px 5px 0 0;
  background: #f9fafb;
}

.container-full-page .container-footer > .ui.dropdown {
  margin-bottom: 1em;
}

.container-full-page .container-footer > .ui.dropdown:last-child {
  margin-bottom: 0;
}

.container-full-page .container-footer {
  padding: 1em;
  border-top: solid 1px #d4d4d5;
  border-radius: 0 0 5px 5px;
  background: #f9fafb;
}

.container-full-page .container-header > .ui.basic.buttons {
  background: #ffffff;
}

@media (max-width: 767px) {
  .container-full-page .container-header {
    padding: 0;
    background: #ffffff;
    border-radius: 0;
    border-top: solid 1px #d4d4d5;
  }

  .container-full-page .container-header > .ui.basic.buttons {
    border: none;
    border-radius: 0;
    border-right: solid 1px #d4d4d5;
  }
}
