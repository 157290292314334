@import url(https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,400,500);
.section__header {
  display: table;
  width: 100%;
  cursor: pointer;
  padding: 1em 0;
}

.section__header h3,
.section__header .icon {
  display: table-cell;
  white-space: nowrap;
  padding-left: 0;
}

.section__header .icon {
  -webkit-transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.section__header.active .icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.section__header h3 {
  font-size: 1.2em;
  line-height: 1.5;
  text-transform: uppercase;
  padding-left: 0.15em;
}

.net-worth__total {
  display: table-cell;
  white-space: nowrap;
  text-align: right;
  font-size: 1.2em;
}

@media (max-width: 767px) {
  .section__header {
    padding-left: 0.5em;
    box-sizing: border-box;
    border-top: solid 1px rgba(0, 0, 0, 0.15);
  }

  .net-worth__total {
    padding-right: 0.8em;
    font-weight: 700;
  }
}

.account-widget-group {
  border-top: solid 1px #d4d4d5;
}

.account-widget__new {
  padding-top: 1em;
  text-align: right;
}

.account-widget__new .ui.button {
  margin: 0;
}

@media (min-width: 768px) {
  .account-widget {
    border: solid 1px #d4d4d5;
    border-radius: 0.28571429rem;
  }

  .accounts-list-wrapper .account-widget {
    border: none;
  }

  .account-widget-group:first-child {
    border-top: none;
  }
}

.account-widget-group__header {
  display: table;
  width: 100%;
  cursor: pointer;
}

.account-widget-group__name,
.account-widget-group__total {
  display: table-cell;
  font-weight: 500;
  background-color: rgba(77, 78, 80, 0.05);
  padding: 0.5em 1em;
}

@media (max-width: 767px) {
  .accounts-list-wrapper .account-widget-group:first-child {
    border-top: none;
  }

  .account-widget-group__name,
  .account-widget-group__total {
    padding: 0.75em 1em;
  }

  .account-widget__new {
    border-top: solid 1px #d4d4d5;
    padding: 1em 0.75em;
  }
}

.account-widget-group__total {
  width: 1px;
  white-space: nowrap;
}

.account-widget-account {
  width: 100%;
  border-top: solid 1px #d4d4d5;
  display: flex;
  flex-flow: row nowrap;
  align-items: top;
}

.account-widget-account__name,
.account-widget-account__balance {
  display: flex;
  padding: 0.5em 1em;
}

.account-widget-account__name {
  flex-basis: 100%;
  white-space: nowrap;
}

.account-widget-account__balance {
  text-align: right;
  white-space: nowrap;
  flex: 1 1;
  flex-flow: column nowrap;
}

.account-widget-account__name {
  padding: 0.5em 0.5em 0.5em 1em;
}

.account-widget-account__edit {
  flex: 1 1;
  padding: 0.75em 0.75em 0.75em 0;
}

.input-right {
  text-align: right;
}

.input-right input {
  text-align: right !important;
  font-family: 'Roboto Mono', monospace !important;
}

.transactions-form__empty {
  border: solid 1px #d4d4d5;
  border-radius: 0.28571429rem;
  padding: 1em;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .transactions-form__empty {
    border: none;
    border-top: solid 1px #d4d4d5;
    border-radius: 0;
  }
}

.transaction-form .ui.labeled.input .label {
  width: 4.9em !important;
  font-family: 'Roboto Mono', monospace !important;
}

.transaction-form-grid {
  display: flex;
  flex-direction: row;
}

.transaction-form-grid .transaction-form-grid__field {
  width: 100%;
}

.transaction-form-grid .transaction-form-grid__field:last-child {
  margin-top: 1em;
}

.transaction-form-grid__column-narrow .transaction-form-grid__field {
  width: 100%;
  margin-top: 1.65em;
}

.transaction-form-grid__column-narrow .transaction-form-grid__field:last-child {
  margin-top: 1em;
  padding-top: 0.1em;
}

.transaction-form-grid.single-line {
  margin-top: 0;
}

@media (min-width: 768px) {
  .input-right.no-label .input {
    margin-top: 1.65em;
  }

  .transaction-form-grid__column-wide {
    width: 68.75%;
    padding-right: 0.3em;
  }

  .transaction-form-grid__column-narrow {
    width: 31.25%;
    padding-left: 0.7em;
  }

  .transaction-form-grid.single-line .transaction-form-grid__field {
    margin-top: 0;
  }

  .transaction-form-grid.single-line
    .transaction-form-grid__column-narrow
    .transaction-form-grid__field:last-child {
    margin-top: 1em;
  }
}

@media (max-width: 767px) {
  .transaction-form-grid {
    margin-top: 1em;
    flex-direction: column;
  }

  .transaction-form-grid__column-wide {
    width: 100%;
  }

  .transaction-form-grid__column-narrow {
    width: 100%;
    display: flex;
  }

  .transaction-form-grid__column-narrow
    .transaction-form-grid__field:first-child {
    padding-right: 1.5em;
  }

  .transaction-form-grid__column-narrow .transaction-form-grid__field {
    margin-top: 1em;
    flex-grow: 1;
  }

  .ui[class*='top attached'].menu {
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
  }

  .ui.segment[class*='bottom attached'] {
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    padding-top: 0 !important;
  }

  .mobile-with-margin {
    margin-top: 1em !important;
  }
}

.transactions-list__wrapper {
  border: solid 1px #d4d4d5;
  border-radius: 0.28571429rem;
  display: flex;
  flex-flow: row wrap;
  font-size: 1.14285714rem;
}

.ui.segment.transactions-list__wrapper {
  box-shadow: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .ui.segment.transactions-list__wrapper {
    border: none;
    border-top: solid 1px #d4d4d5;
    border-radius: 0;
  }
}

.search-page > .ui.segment.transactions-list__wrapper {
  border: none;
}

.search-page > .transactions-list__wrapper {
  border: none;
}

.transactions-list__empty {
  padding: 1em;
  text-align: center;
}

.transaction-item {
  flex: 1 1 100%;
  width: 100%;
  border-bottom: dotted 1px #d4d4d5;
  padding: 0.75em 1em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.transaction-item .ui.label {
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}

.transaction-item:last-child {
  border-bottom: none;
}

.transaction-item__date {
  flex: 1 1;
  white-space: nowrap;
  color: #7c7c7d;
}

.transaction-item__info {
  flex-basis: 100%;
  padding-left: 0.5em;
}

.transaction-item .arrow {
  margin: 0 0.3em !important;
}

.transaction-item__info__note {
  padding-left: 0.5em;
  color: #929293;
}

.transaction-item__amount {
  flex: 1 1;
  white-space: nowrap;
  text-align: right;
}

.transaction-item__edit {
  flex: 0 1;
  white-space: nowrap;
  text-align: right;
}

.transaction-item__edit > .ui.button {
  margin: 0 0 0 0.75em;
}

.ui.modal.transaction > .content {
  padding: 0 1px !important;
}

.ui.modal.transaction > .ui.header {
  border-bottom: none;
  padding: 1.25rem 1rem;
}

.ui.modal.transaction .ui.top.attached,
.ui.modal.transaction .ui.top.attached.menu > .item:first-child {
  border-radius: 0;
}

@media (min-width: 768px) {
  .ui.modal.transaction > .actions {
    border-top: none;
  }
}

@media (max-width: 767px) {
  .transactions-list__wrapper {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  .transaction-item {
    flex-flow: row wrap;
  }

  .transaction-item__date {
    flex: 1 1;
    order: 1;
    white-space: nowrap;
  }

  .transaction-item__info {
    flex-basis: 100%;
    order: 4;
    padding-left: 0;
    margin-top: 0.5em;
  }

  .transaction-item__amount {
    flex: 1 1;
    order: 2;
    white-space: nowrap;
  }

  .transaction-item__edit {
    order: 3;
  }
}

.transactions-list-footer {
  border-top: solid 1px #d4d4d5;
}

@media (min-width: 768px) {
  .transactions-list-footer {
    display: flex;
    justify-content: flex-end;
  }
  .transactions-list-footer > .ui.table {
    max-width: 400px;
  }
}

.transactions-list-footer
  .ui[class*='very basic'].table:not(.sortable):not(.striped)
  td:last-child {
  padding: 0.78571429em;
  padding-right: 1.2em;
}

.transactions-list-pager {
  border-top: solid 1px #d4d4d5;
  background: #f9fafb;
  padding: 1em;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .transactions-list-footer
    .ui[class*='very basic'].table:not(.sortable):not(.striped)
    td:first-child {
    padding: 0.78571429em;
  }

  .transactions-list-pager {
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .container-header > .ui.basic.buttons {
    max-width: 360px;
    background: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  }
}

.transactions-filter-modal .content {
  text-align: center;
}

@media (max-width: 767px) {
  .transactions-filter-modal .DayPicker-wrapper {
    padding: 0;
  }

  .transactions-filter-modal .DayPicker-Month:last-child {
    margin-top: 1em;
  }
}

.transactions-filters-applied {
  padding: 0.75em;
  border-bottom: solid 1px #d4d4d5;
  background: #f8f8f8;
  box-shadow: 0 1px 1px 0 rgba(34, 36, 38, 0.15) inset;
}

.transactions-filters-applied .ui.label {
  margin: 0.24285714em;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}

.transactions-filters-applied .ui.label:first-child {
  margin-left: 0.24285714em;
}

.ui.form.account-form .input-right .mono {
  display: block;
  margin-top: 0.75em;
}

.ui.form.account-form .field.checkbox {
  padding-top: 0.75em;
}

.ui.form.account-form .fields.no-margin {
  margin-bottom: 0 !important;
}

.mt-report .ct-chart {
  margin-top: 1em;
}

.mt-report-expense-income .ct-series-a .ct-bar {
  stroke: #7cb342;
}

.mt-report-expense-income .ct-series-b .ct-bar {
  stroke: #f44336;
}

.mt-report-expense-income .ct-bar {
  stroke-width: 1em;
  stroke-opacity: 0.75;
}

.mt-report-expense-tags .ct-bar {
  stroke: #f44336;
  stroke-width: 1em;
  stroke-opacity: 0.75;
}

.mt-report-net-worth .ct-point,
.mt-report-net-worth .ct-line {
  stroke: #7cb342;
}

.mt-report-net-worth .ct-area {
  fill: #7cb342;
}

.chartist-tooltip {
  background: #d4d4d5;
  border-radius: 3px;
}

.chartist-tooltip:before {
  border-top-color: #d4d4d5;
}

.mt-report-stats {
  padding: 1em;
  border-bottom: solid 1px;
  border-bottom-color: #d4d4d5;
}

@media (max-width: 767px) {
  .ui.statistic {
    width: 100%;
  }

  .ui.statistic+.ui.statistic {
    margin: 0 1.5em 1em;
  }
}
.exchange-rate-table {
  margin: 0 !important;
  padding: 0 1em !important;
}

.exchange-rate-table .ui.button {
  margin-top: 1em;
}

.section__body .exchange-rate-table {
  padding: 0 !important;
}

.mt-dataExport {
  max-width: 680px;
}

.mt-dataExport input[type='file'] {
  display: none;
}

.mt-dataImport {
  max-width: 680px;
}

.mt-dataImport input[type='file'] {
  display: none;
}

.auth--input-field {
  max-width: 420px;
  margin: 2em auto 2em;
}

.auth--input-field .ui.input {
  margin-bottom: 0.5em;
}

.logo {
  position: relative;
  height: 60px;
  top: -16px;
  left: -16px;
  width: 125px;
  text-align: center;
  padding: 15px;
}

.logo img {
  height: 32px;
}

header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 16px;
  height: 62px;
  width: 100%;
  background-color: #3f51b5;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 6px 0 rgba(34, 36, 38, 0.15),
    0 2px 18px 0 rgba(34, 36, 38, 0.17);
  z-index: 102;
}

header .ui.header {
  display: flex !important;
  flex-direction: row;
  align-items: stretch;
  vertical-align: middle;
}

header .content {
  flex: 1 1;
}

header .ui.header .icon {
  margin-top: 3px;
}

body {
  height: auto !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@media (min-width: 768px) {
  body {
    background-color: #f5f5f5;
  }
}

nav {
  position: fixed;
  top: 60px;
  width: 125px;
  min-height: 100%;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  z-index: 101;
}

@media (min-width: 1194px) {
  nav {
    box-shadow: 0 2px 6px 0 rgba(34, 36, 38, 0.15),
      0 2px 18px 0 rgba(34, 36, 38, 0.17);
  }
}

nav.open {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

nav.closed {
  -webkit-transform: translate(-125px, 0);
          transform: translate(-125px, 0);
}

nav .menu {
  min-height: 100vh !important;
  border: none !important;
  border-radius: 0 !important;
}

nav .menu .item:last-child {
  border-radius: 0 !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15) !important;
}

.ui.menu {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ui.blue.menu .active.item,
.ui.menu .blue.active.item {
  color: #3f51b5 !important;
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.ui.menu .active.item {
  box-shadow: inset 0 1px 4px 0 rgba(34, 36, 38, 0.12) !important;
}

.container-raised-desktop {
  display: block;
  background-color: white;
  padding: 1em 0 0 0;
  max-width: 700px;
  font-size: 1.14285714rem;
  line-height: 1.5;
}

.container-raised-desktop .ui.header,
.container-raised-desktop .ui.form,
.container-raised-desktop p {
  margin: 1rem;
}

.container-raised-desktop .ui.form {
  margin-bottom: 0;
}

.container-raised-desktop .form-submit {
  padding: 1em 0 0 0;
  text-align: right;
}

.container-raised-desktop .form-submit .button {
  margin: 0;
}

.container-full-page {
  display: block;
  background-color: white;
  font-size: 1.14285714rem;
  line-height: 1.5;
}

.ui.message {
  max-width: 960px !important;
  margin: 1em auto;
  padding: 1em !important;
}

.ui.message .content {
  margin-top: 0.5em;
}

@media (min-width: 768px) {
  .container {
    max-width: calc(100% - 125px);
    margin-left: 125px;
    margin-top: 1em;
  }

  .container-raised-desktop {
    margin: 1em auto;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
}

@media (min-width: 768px) and (max-width: 1193px) {
  html,
  body {
    background-color: white;
  }

  .container-full-page {
    padding: 0 1em 1em 1em;
    margin-left: 3px;
  }

  .ui.warning {
    margin: 1em 1em 0 !important;
  }
}

@media (min-width: 1194px) {
  .container-full-page {
    max-width: 960px;
    margin: 1em auto;
    padding: 0 1em 1em 1em;
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15);
  }
}

.container-full-page.flat {
  padding: 0;
}

header .ui.header {
  color: white;
}

header .ui.header h2 {
  font-weight: 300;
}

.ui.header:first-child {
  margin-top: 0;
}

.ui.header .icon {
  padding-top: 0;
  font-weight: 300;
}

.ui.primary.button {
  background-color: #3f51b5 !important;
}

.ui.button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ui.button,
.ui.label,
.ui.header {
  font-weight: 400 !important;
}

.ui.dropdown .menu .active.item {
  font-weight: 500 !important;
}

.ui.header {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin: 0;
}

.mono {
  font-family: 'Roboto Mono', monospace;
  color: #686869;
}

.positive {
  color: #21ba45;
}

.negative {
  color: #db2828;
}

@media (max-width: 767px) {
  .ui.grid {
    margin: 0;
  }

  .ui.grid .row .column {
    padding: 0;
  }

  .container-raised-desktop .form-submit {
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    padding: 1rem;
  }

  .container-full-page .ui.grid .row {
    padding: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  }

  .ui.warning {
    margin: 0 !important;
    box-shadow: none !important;
    padding: 1em !important;
  }

  .mt-settings .section__body {
    padding: 1em;
    border-top: solid 1px #d4d4d5;
  }
}

.ui.form .field > label {
  font-weight: 500;
}

.container-full-page .container-header {
  padding: 1em;
  border-bottom: solid 1px #d4d4d5;
  border-radius: 5px 5px 0 0;
  background: #f9fafb;
}

.container-full-page .container-footer > .ui.dropdown {
  margin-bottom: 1em;
}

.container-full-page .container-footer > .ui.dropdown:last-child {
  margin-bottom: 0;
}

.container-full-page .container-footer {
  padding: 1em;
  border-top: solid 1px #d4d4d5;
  border-radius: 0 0 5px 5px;
  background: #f9fafb;
}

.container-full-page .container-header > .ui.basic.buttons {
  background: #ffffff;
}

@media (max-width: 767px) {
  .container-full-page .container-header {
    padding: 0;
    background: #ffffff;
    border-radius: 0;
    border-top: solid 1px #d4d4d5;
  }

  .container-full-page .container-header > .ui.basic.buttons {
    border: none;
    border-radius: 0;
    border-right: solid 1px #d4d4d5;
  }
}

